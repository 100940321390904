<template>
  <el-main>
    <div class="title">
      <div>活动标题：{{ title }}</div>
      <div>活动时间：{{ time }}</div>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="用户信息：">
        <el-input v-model="searchForm.nick_name" size="small"></el-input>
      </el-form-item>
      <el-form-item label="奖励类别：">
        <el-select v-model="searchForm.type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="现金奖励" :value="1"></el-option>
          <el-option label="积分积分" :value="2"></el-option>
          <el-option label="未中奖" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="奖励名称：">
        <el-input v-model="searchForm.reward" size="small"></el-input>
      </el-form-item>
      <el-form-item label="产生时间：">
        <el-date-picker size="small" v-model="searchForm.starttime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="total-num">
        <div>参与人数：<span>{{joinnum}}</span></div>
        <div>筛选后金额：<span>￥{{joincash || "0.00"}}</span></div>
        <div>筛选后积分：<span>{{joinintergral || 0}}</span></div>
    </div>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nick_name" label="用户信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nick_name" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="参与时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="奖励类别" align="center">
        <template v-slot="{ row }">{{ row.type == 1 ? '现金奖励' : row.type == 2 ? '积分奖励' : '未中奖' }}</template>
      </el-table-column>
      <el-table-column prop="type" label="现金/积分" align="center">
        <template v-slot="{ row }">{{ Number(row.reward) || '--' }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      luckdraw_id: '',
      title: '',
      time: '',
      searchForm: {
        page: 1,
        rows: 10,
        nick_name: '',
        type: '',
        reward: '',
        starttime: '',
        endtime: '',
      },
      list: [],
      total: 0,
      joincash:'',
      joinintergral:'',
      joinnum:''
    };
  },
  created() {
    this.luckdraw_id = this.$route.query.id;
    this.title = this.$route.query.title;
    this.time = this.$route.query.time;
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nick_name: '',
        type: '',
        reward: '',
        starttime: '',
        endtime: '',
      };
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      searchForm.luckdraw_id = this.luckdraw_id;
      if (searchForm.starttime) {
        searchForm.endtime = searchForm.starttime[1] / 1000;
        searchForm.starttime = searchForm.starttime[0] / 1000;
      }
      this.$axios.post(this.$api.serviceProvider.luckyDrawData, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
          this.joinnum = res.result.joinnum;
          this.joincash = res.result.joincash;
          this.joinintergral = res.result.joinintergral;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .title {
    font-size: 14px;
    display: flex;
    margin-bottom: 20px;
    div:nth-child(1) {
      margin-right: 50px;
    }
  }
  .total-num{
      display: flex;
      margin-top:15px;
      margin-bottom: 15px;
      div{
          margin-right:50px;
          span{
              color: #00b578;
              font-weight: bold;
          }
      }
  }
}
</style>